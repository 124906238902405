import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Tags,
  Details,
  Metadata,
  Settings,
  Resources,
  Alerts,
  Costing,
  Warning,
  ServiceMetrics,
  ComplianceMetrics,
  WaffleDetails,
  Associations,
  TaggingRecommendations,
  ChangeHistory
} from "./SystemDetails";
import { Scrollspy, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { clearIndexes } from "./SystemActions";

const SystemScreen = props => {
  const key = props.data.key;
  const [system, setSystem] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [errorMessageAlerts, setErrorMessageAlerts] = useState(null);
  const [account, setAccount] = useState(null);
  // const [maintAlerts, setMaintAlerts] = useState(null);
  const [panels, setPanels] = useState([]);
  const dispatch = useDispatch();
  const [warningRenderd, setWarningRendered] = useState(false);
  const [tags, setTags] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [metadataConfig, setMetadataConfig] = useState(null);
  let hasTaggingRecommendations = false;

  useEffect(() => {
    dispatch(clearIndexes());
  });

  useEffect(() => {
    setPanels([]);
    fetch(config.apiUrl + "/ssot/systems/" + key, {
      // fetch("http://127.0.0.1:8000/yo/" + key, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response);
        if (response.status === "fail") {
          console.log(response);
          setErrorMessage(response);
        } else if (!response.type) {
          throw Error("Failure in system retrieval");
        } else {
          setErrorMessage(null);
          // use these args to mock system state changes
          //response.is_admin = false;
          //delete response.metadata;
          //response.type = "aws_account";
          //response.state = "inactive";
          setSystem(response);
          setTags(response.tags);
          setMetadata(response.metadata);
          // set default values if metadata_config does not exist or is empty
          let defaultConfig = {};
          if (
            !response.metadata_config ||
            Object.keys(response.metadata_config).length === 0
          ) {
            for (let key in response.metadata) {
              defaultConfig[key] = {
                category: "General",
                visible: false
              };
            }
            setMetadataConfig(defaultConfig);
          } else {
            setMetadataConfig(response.metadata_config);
          }
        }
      })
      .catch(error => {
        setErrorMessage(error);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (
      system &&
      system.type === "aws_account" &&
      tags &&
      metadata &&
      metadataConfig
    ) {
      fetch(config.apiUrl + "/ssot/systems/account/tagguid/" + key, {
        // fetch("http://127.0.0.1:8000/account/tagguid/" + key, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            if (
              response.message === "Account not found for tagguid" &&
              !warningRenderd
            ) {
              generatePanels(system, account, false);
              setAccount(null);
            } else {
              throw Error(response);
            }
          } else {
            setAccount(response);
            generatePanels(system, response, true);
          }
        })
        .catch(error => {
          console.log(error);
          generatePanels(system, account, false, error);
          setAccount(null);
        });
    } else if (system && system.type !== "aws_account" && tags) {
      generatePanels(system);
    }
    taggingRecommendations();
  }, [system, tags, metadata, metadataConfig, props.accessToken]);

  const taggingRecommendations = () => {
    if (metadata) {
      for (var mkey in system.metadata) {
        let category = "";

        if (metadataConfig && metadataConfig[mkey]) {
          category = metadataConfig[mkey].category;
        }
        if (category == "Tagging Recommendation") {
          hasTaggingRecommendations = true;
          break;
        }
      }
    }
  };

  taggingRecommendations();

  const generatePanels = (
    system,
    account = null,
    accountFound = false,
    error = null
  ) => {
    let local_panels = [];
    if (system.type === "aws_account" && !accountFound) {
      local_panels.push({
        text: "Configuration Warning",
        hash: "configuration_warning",
        component: (
          <Warning
            key="configuration warning"
            id="configuration_warning"
            message={error}
          />
        )
      });
    }
    if (!system.is_admin) {
      local_panels.push({
        text: "Ownership Warning",
        hash: "ownership_warning",
        component: (
          <Warning
            key="ownership warning"
            id="ownership_warning"
            type="ownership"
            system={system}
            {...props}
          />
        )
      });
    }
    /*
    if (hasTaggingRecommendations) {
      local_panels.push({
        text: "Configuration Recommendations",
        hash: "configuration_Recommendations",
        component: (
          <Warning
            key="configuration Recommendations"
            id="configuration_Recommendations"
            type="tagging_recommendation"
            system={system}
            {...props}
          />
        )
      });
    }
    */
    if (system.state !== "active") {
      local_panels.push({
        text: "Deleted Warning",
        hash: "deleted_warning",
        component: (
          <Warning
            key="deleted warning"
            id="deleted_warning"
            type="state"
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "Details",
      hash: "details",
      component: (
        <Details key="details" id="details" system={system} {...props} />
      )
    });
    if (system && system.type === "aws_account" && account) {
      local_panels.push({
        text: "Account Details",
        hash: "accountDetails",
        component: (
          <WaffleDetails
            key="accountDetails"
            id="accountDetails"
            system={system}
            account={account}
            errorMessage="failure"
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "change history",
      hash: "change_history",
      component: (
        <ChangeHistory
          title="change History"
          key="change_History"
          id="change_history"
          system={system}
          account={account}
          {...props}
        />
      )
    });
    local_panels.push({
      text: "Tags",
      hash: "tags",
      component: (
        <Tags
          key="tags"
          id="tags"
          system={system}
          account={account}
          tags={tags}
          setTags={setTags}
          metadata={metadata}
          setMetadata={setMetadata}
          metadataConfig={metadataConfig}
          setMetadataConfig={setMetadataConfig}
          {...props}
        />
      )
    });
    if (hasTaggingRecommendations) {
      local_panels.push({
        text: "Tagging Recommendations",
        hash: "tagging_Recommendations",
        component: (
          <TaggingRecommendations
            key="tagging_Recommendations"
            id="tagging_Recommendations"
            system={system}
            metadata={metadata}
            setMetadata={setMetadata}
            metadataConfig={metadataConfig}
            setMetadataConfig={setMetadataConfig}
            tags={tags}
            setTags={setTags}
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "Metadata",
      hash: "metadata",
      component: (
        <Metadata
          key="metadata"
          id="metadata"
          system={system}
          metadata={metadata}
          setMetadata={setMetadata}
          metadataConfig={metadataConfig}
          setMetadataConfig={setMetadataConfig}
          tags={tags}
          setTags={setTags}
          {...props}
        />
      )
    });
    local_panels.push({
      text: "System Specific Compliance",
      hash: "system_specific_compliance",
      component: (
        <ComplianceMetrics
          key="system_specific_compliance"
          id="system_specific_compliance"
          system={system}
          {...props}
        />
      )
    });
    if (system && system.type === "aws_account" && account) {
      local_panels.push({
        text: "Account Specific Compliance",
        hash: "account_specific_compliance",
        component: (
          <ComplianceMetrics
            key="account_specific_compliance"
            id="account_specific_compliance"
            system={system}
            account={account}
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "Service Metrics",
      hash: "service_metrics",
      component: (
        <ServiceMetrics
          key="service_metrics"
          id="service_metrics"
          system={system}
          {...props}
        />
      )
    });
    local_panels.push({
      text: "Costing",
      hash: "costing",
      component: (
        <Costing
          key="costing"
          id="costing"
          system={system}
          account={account}
          {...props}
        />
      )
    });
    if (system && system.type === "aws_account" && account) {
      local_panels.push({
        text: "Associated Systems",
        hash: "associations",
        component: (
          <Associations
            title="Associated Systems"
            key="associations"
            id="associations"
            system={system}
            account={account}
            {...props}
          />
        )
      });
    } else {
      local_panels.push({
        text: "Associated AWS Accounts",
        hash: "associations",
        component: (
          <Associations
            title="Associated AWS Accounts"
            key="associations"
            id="associations"
            system={system}
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "Resources",
      hash: "resources",
      component: (
        <Resources
          title="Resources"
          key="resources"
          id="resources"
          system={system}
          account={account}
          {...props}
        />
      )
    });
    if (system && system.type === "aws_account" && account) {
      local_panels.push({
        text: "Alerts",
        hash: "alerts",
        component: (
          <Alerts
            title="Alerts"
            key="alerts"
            id="alerts"
            system={system}
            account={account}
            {...props}
          />
        )
      });
    }
    local_panels.push({
      text: "Settings",
      hash: "settings",
      component: (
        <Settings
          key="settings"
          id="settings"
          system={system}
          account={account}
          {...props}
        />
      )
    });
    setPanels(local_panels);
  };

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
          .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
            color: #e5e5e5;
            padding: 5px;
          }
          .epic__sectionContent__947 {
            margin-top: 0px
          }
          .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
          }
          .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
         }
          .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }`}
      </style>
      {!system ||
      (system.type === "aws_account" && !account && !panels) ||
      !panels ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />

          // <APIError error={errorMessage} />
        )
      ) : (
        <div className="scrollspy-container">
          {errorMessage}
          <Scrollspy
            title="System Nav"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(SystemScreen);
