import React, { useState, useEffect } from "react";
import { Section, Button, Modal } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import { SortableTable } from "../components/table/index.js";

export const ChangeHistory = props => {
  const key = props.data.key;
  const [auditData, setAuditData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setModal] = useState(false);
  const [isCollapsed] = useState(true);
  const [tableRecord, setTableRecord] = useState({});
  const modalStyle = {
    maxWidth: "500px",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    width: "100%"
  };
  const showModalAndTableRecord = record => {
    setTableRecord(record);
    setModal(true);
  };

  useEffect(() => {
    fetch(config.apiUrl + "/ssot/systems/auditrecords/id?system_id=" + key, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setTimeout(() => {
            console.log(response);
            setErrorMessage(response);
          }, 5000);
        } else {
          setAuditData(response);
        }
      });
  }, [key, props.accessToken]);

  const auditsorttableprops = {
    data: auditData,
    fields: ["systemid", "system_name", "username", "timestamp", "action"],
    columns: [
      {
        Header: "System ID",
        accessor: "systemid"
      },
      {
        Header: "System Name",
        accessor: "system_name"
      },
      {
        Header: "Username",
        accessor: "username"
      },
      {
        Header: "Timestamp",
        accessor: "timestamp"
      },
      {
        Header: "Action",
        accessor: "action"
      },
      {
        Header: "Details",
        accessor: "details",
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              const currentRow = row.original;
              if (row.original.action === "Modify") {
                const preRow = auditData[row.index - 1];
                if (preRow) {
                  //const mergerecord = { ...currentRow, ...preRow };
                  const mergerecord = [currentRow, preRow];
                  showModalAndTableRecord(mergerecord);
                  setModal(true);
                }
              } else {
                showModalAndTableRecord([row.original]);
                setModal(true);
              }
            }}
          >
            Details
          </Button>
        )
      }
    ]
  };
  const ShowRecord = ({ tableRecord }) => {
    return (
      <div
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          overflow: "scroll",
          maxHeight: "400px",
          width: "100%",
          borderCollapse: "collapse"
        }}
      >
        {tableRecord && tableRecord.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    //backgroundColor: "#D6EEEE",
                    padding: "8px",
                    border: "2px solid blue",
                    borderRadius: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Key
                </th>
                <th
                  style={{
                    //backgroundColor: "#D6EEEE",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Current Value
                </th>
                {tableRecord[1] && (
                  <th
                    style={{
                      //backgroundColor: "#D6EEEE",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      fontWeight: "bold"
                    }}
                  >
                    Previous Value
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.keys(tableRecord[0]).map(key => (
                <tr key={key}>
                  <td
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color:
                        tableRecord[1] &&
                        JSON.stringify(tableRecord[0][key]) !==
                          JSON.stringify(tableRecord[1][key])
                          ? key === "delegates" ||
                            key === "tags" ||
                            key === "metadata_" ||
                            key === "metadata_config" ||
                            key === "owner" ||
                            key === "system_name"
                            ? "blue"
                            : "black"
                          : "black"
                    }}
                  >
                    {key}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd"
                    }}
                  >
                    {typeof tableRecord[0][key] === "object"
                      ? JSON.stringify(tableRecord[0][key]) // Handling nested objects
                      : tableRecord[0][key]}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd"
                    }}
                  >
                    {tableRecord[1] && typeof tableRecord[1][key] === "object"
                      ? JSON.stringify(tableRecord[1][key]) // Handling nested objects for value2
                      : tableRecord[1]
                      ? tableRecord[1][key]
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No records available</p>
        )}
      </div>
    );
  };

  return (
    <Section id={props.id} title="Change History" collapsible={isCollapsed}>
      <div style={{ padding: "10px" }}>
        {errorMessage && <APIError message={errorMessage} />}
        {auditData && <SortableTable {...auditsorttableprops} />}
      </div>
      {showModal && (
        <div style={modalStyle}>
          <Modal
            onClose={() => {
              setModal(false);
            }}
            closeButton
            show={showModal}
          >
            <h2>System Data</h2>
            <div
              style={{
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                borderWidth: "auto",
                margin: "10px",
                overflow: "auto",
                maxHeight: "400px",
                width: "auto",
                borderCollapse: "collapse"
              }}
            >
              {tableRecord && <ShowRecord tableRecord={tableRecord} />}
            </div>
            <div style={{ textAlign: "center", padding: "12px" }}>
              <Button
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </Button>
            </div>
            <footer style={{ color: "blue" }}>
              <li>
                Highlighted in Blue indicates the columns that have been
                modified
              </li>
            </footer>
          </Modal>
        </div>
      )}
    </Section>
  );
};
